
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SuccessPageComponent extends Vue {
  @Prop() title!: string;
  @Prop() body!: string;
  @Prop() btnMessage!: string;
  @Prop() linkTo!: string;
}
